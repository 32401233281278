<template>
  <v-container fluid class="pa-0 ma-0">
    <v-toolbar flat class="pt-2">
      <v-toolbar-title>User List</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>

      <div style="width: 100px" class="mr-3">
        <v-select
          v-model="searchSelected"
          :items="searchItems"
          item-text="text"
          item-value="val"
          label="검색대상"
          @change="searchObjectChange()"
        ></v-select>
      </div>
      <v-text-field
        label="검색"
        append-icon="search"
        v-model="params.search"
        clearble
      ></v-text-field>

      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on">등록</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row no-gutters>
                <v-col cols="12" xs="12">
                  <v-text-field v-model="editedItem.email" label="user email"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12">
                  <v-text-field v-model="editedItem.name" label="user name"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-card :elevation="0">
      <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="검색결과가 없습니다"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >edit</v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >delete</v-icon>
        </template>
      </v-data-table>
      <!-- 외부 페이지네이션 -->
      <div class="text-center pt-3">
        <v-pagination
          v-model="options.page"
          :length="pages"
          :total-visible="7"
        ></v-pagination>
        <!-- <data-table-pagination
            :total-pages="pages"
            :pagination-obj="options"
            :total-visible="7"
          ></data-table-pagination> -->
      </div>
      <div>
        {{ numberFormat(amount) }}
        <v-btn
          @click="$router.push(`/bbs/2`)"
        >게시판 상세페이지 바로가기</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'

// 구분: filters
import numberFormat from '@/filters/numberFormat'

export default {
  components: {
  },
  data: () => ({
    ui: null, // 유저정보
    amount: 3000000,
    dialog: false,
    headers: [
      {
        text: '',
        value: 'id',
        align: 'center',
        sortable: false,
        divider: false,
        width: '8%',
        class: 'hidden-sm-and-down'
      },
      { text: 'email', value: 'email', align: 'left', divider: false },
      { text: 'name', value: 'name', align: 'left', divider: false },
      { text: 'dbcode', value: 'dbcode', align: 'center', divider: false },
      { text: 'createdAt', value: 'createdAt', align: 'center', divider: false, width: '12%', class: 'hidden-sm-and-down' },
      { text: 'Actions', value: 'actions', align: 'center', divider: false, sortable: false, width: '10%', class: 'hidden-sm-and-down' }
    ],
    users: [],
    editedIndex: -1,
    editedItem: {
      email: '',
      name: ''
    },
    defaultItem: {
      email: '',
      name: ''
    },
    // paging 관련
    /* loading: false,
    options: { // 초기값을 여기서 지정한다.
      sortBy: 'createdAt', // 생성일 정렬을 초기값으로
      descending: true, // 내림차순을 초기값으로
      rowsPerPage: 5 // 리스트 갯수 초기값
      // ,sortBy: 'title' // 정렬대상 초기값을 제목으로
    },
    rowsPerPageItems: [ 1, 2, 5, 10, 15, 30, 50 ], // 검색 갯수
    */
    // version 2.0 v-data-talbe 파라미터
    totalItems: 0,
    loading: true,
    options: {
      itemsPerPage: 3 // 초기값
    },
    // 검색 셀렉트 배열
    searchItems: [
      { text: '이메일', val: 'email' },
      { text: '이름', val: 'name' },
      { text: 'dbcode', val: 'dbcode' }
    ],
    // 선택된 검색 필드
    searchSelected: 'name',
    params: { // 검색용 인자 객체
      draw: 0,
      searchField: 'name',
      search: '',
      sort: 'createdAt',
      order: 'ASC',
      offset: 0,
      limit: 1
    },
    timeout: null
  }),

  watch: { // ! 와우. 다이얼로그를 저장하면 변경된게 자동으로 잡힌다!!
    dialog (val) {
      // console.log(val) // 다이얼로그에서 작성한 데이터가 찍힌다
      val || this.close()
    },
    // version 2.0 v-data-table
    options: {
      handler () {
        this.list()
      },
      deep: true
    },
    'params.search': { // 검색어 자동 감지
      handler () {
        this.options.page = 1 // 검색어 검색시 1페이지로 이동하게 함
        this.delay()
      }
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? '등록' : '수정'
    },
    setOffset () {
      if (!this.options.page) return 0
      return (this.options.page - 1) * this.options.itemsPerPage
    },
    setLimit () {
      return this.options.itemsPerPage
    },
    setSort () {
      // let order = this.options.sortBy
      // if (!this.options.sortBy) order = 'createdAt'
      let order = 'createdAt'
      return order
    },
    setOrder () {
      // return this.options.descending ? 'DESC' : 'ASC'
      return 'DESC'
    },
    pages () {
      if (this.options.itemsPerPage == null ||
          this.totalItems == null
      ) return 0
      return Math.ceil(this.totalItems / this.options.itemsPerPage)
    }
  },

  mounted () {
    // 약간 딜레이를 주고 초기화 함수 호출(비동기)
    sleep(300).then(() => this.initialize())

    sleep(2000).then(() => console.log('sleep test'))

    // 저장된 유저정보를 패칭
    this.ui = this.$store.state.ui
  },

  methods: {
    numberFormat,
    initialize () {
      this.list()
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    searchObjectChange () {
      // console.log(this.searchObject)
    },
    async list () {
      try {
        this.loading = true
        this.params.draw++
        this.params.offset = this.setOffset
        this.params.limit = this.setLimit
        this.params.sort = this.setSort
        this.params.order = this.setOrder

        // 중요: 검색어가 있으면 검색셀렉트 값을 제대로 매칭시켜야 함
        if (this.params.search) {
          this.params.searchField = this.searchSelected
        }

        const { data } = await this.$axios.get('users/list', { params: this.params })
        if (!data.success) throw new Error(`list error: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        // this.options.totalItems = data.totalItems
        // NOTE: v 2.0 v-data-table
        this.totalItems = data.totalItems

        // 리스트
        await data.list.map(u => {
          u.createdAt = u.createdAt.substr(2, 8).replace(/-/gi, '.')
          u.actions = 'actions' // actions 필드를 붙여 넣는다
        })
        this.users = data.list

        this.loading = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    delay () { // 검색어 검색시 약간의 딜레이를 줘야 한다.
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, 1000)
    },
    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    async deleteItem (item) {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('삭제', '정말 삭제?', { color: 'red' })) {
          const index = this.users.indexOf(item)
          const id = this.users[index].id
          const { data } = await this.$axios.delete(`users/${id}`)
          if (data.success) {
            // this.users.splice(index, 1) // 전체 리스트를 다시 패칭 하는 것보다 수정된 것만 바꿔치기
            this.list()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    async save () {
      if (this.editedIndex > -1) { // 수정
        try {
          const { data } = await this.$axios.put('users', this.editedItem)
          if (data.success) {
            // 전체 리스트를 다시 패칭 하는 것보다 수정된 것만 바꿔치기
            data.user.createdAt = this.$moment(data.user.createdAt).format('YY.MM.DD')
            Object.assign(this.users[this.editedIndex], data.user)
          }
        } catch (e) {
          this.sbpop(e)
        }
      } else { // 등록
        try {
          const { data } = await this.$axios.post('users', this.editedItem)
          if (data.success) {
            this.list() // 리스트 재패칭
          }
        } catch (e) {
          this.sbpop(e)
        }
      }
      this.close()
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
